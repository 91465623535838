.layoutBox {
  padding: 0 0.3rem;
}
.menuBox {
  padding: 0.24rem 0 0.15rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 2px 18px #cacaca;
}
.swipeBanner {
  height: 1.7rem;
  margin-bottom: 0.15rem;
  overflow: hidden;
}
.swipeBanner .banner {
  width: 6.9rem;
  height: 1.7rem;
  display: block;
}
.swipeNotice {
  background-color: #fff;
  color: #666666;
  font-size: 0.28rem;
  height: 0.62rem;
  line-height: 0.62rem;
  padding: 0 0.15rem 0 0.65rem;
  background: #fff url(../../assets/imgs/icon/home_icon_notice.png) no-repeat left 0.15rem center;
  background-size: 0.32rem 0.3rem;
  border-radius: 0.1rem;
  margin-bottom: 0.15rem;
  overflow: hidden;
}
.swipeNotice .icon_arrR {
  float: right;
  margin: 0.2rem 0.1rem 0 0.2rem;
}
.swipeNotice .van-swipe-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.rankBox {
  margin-bottom: 0.25rem;
}
.rankBox .caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.6rem;
  margin-bottom: 0.8rem;
}
.rankBox .caption .title {
  font-size: 0.32rem;
  font-family: PingFang SC;
}
.rankBox .caption .more {
  font-size: 0.24rem;
}
.rankBox .list {
  display: flex;
  align-items: flex-end;
}
.indexTabList {
  padding: 0 10px;
}
.indexTabList .tabTit {
  color: #999999;
  font-size: 0.32rem;
  font-family: 'Bahnschrift', 'PingFangSC-Regular', 'PingFang SC', '微软雅黑', 'Microsoft Yahei';
  line-height: 0.7rem;
  height: 0.7rem;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  text-transform: capitalize;
}
.indexTabList .tabTit li {
  padding-bottom: 0.68rem;
}
.indexTabList .tabTit .on {
  color: #373737;
  font-size: 0.4rem;
  font-weight: bold;
  border-bottom: 1px solid #373737;
}
.menuLine {
  padding: 8px 0;
  position: relative;
}
.menuLine .menuName {
  font-size: 0.24rem;
  color: #373737;
  display: inline-block;
  margin-top: 0.03rem;
}
.menuLine .menuItem {
  font-size: 0.3rem;
  color: #373737;
  opacity: 0.5;
  line-height: 0.6rem;
  padding: 0 0.3rem;
  min-width: 20%;
  position: relative;
  text-transform: capitalize;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.menuLine .menuItem:first-child {
  padding-left: 0;
}
.menuLine .menuItem:first-child::before {
  display: none;
}
.menuLine .menuItem:last-child {
  padding-right: 0;
}
.menuLine .menuItem:last-child {
  margin-right: 0;
}
.menuLine .menuItem.on {
  opacity: 1;
  font-weight: 700;
  font-size: 0.32rem;
  color: #3893fb;
}
.menuLine .dropdownBtn {
  color: #3893fb;
  font-size: 0.32rem;
}
.indexLoading {
  text-align: center;
  padding-top: 1rem;
}
.bottomTips {
  color: #3893fb;
  font-size: 0.24rem;
  text-transform: uppercase;
  padding: 0 0.3rem;
  width: 100%;
  height: 0.52rem;
  line-height: 0.52rem;
  background: #f3f8ff;
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
}
.list-item0 {
  margin-left: 0.2rem;
}
.list-item2 {
  margin-left: 0.2rem;
}
.container {
  background-color: #f5f5f5;
}
.new_list {
  font-size: 12px;
  font-family: PingFang SC;
  color: #ffffff;
  padding: 0.2rem 0.4rem 0.2rem 0.3rem;
  border-radius: 6px;
  background-image: linear-gradient(to right, #8ec4ff, #ddacff);
  background-color: #8ec4ff;
}
.new_list .leijishouyi {
  margin-bottom: 0.1rem;
}
.new_list .list_item {
  padding: 0.1rem 0;
  display: flex;
  align-items: center;
}
.new_list .list_item .phone {
  width: 35%;
}
.new_list .list_item .leiji {
  width: 33%;
}
.new_list .list_item .jinri {
  width: 32%;
}
.rankCon {
  height: 3.3rem;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
}
.rankCon .rankItem {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
}
.rankCon .rankItem .crown {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -70%);
}
.rankCon .rankItem .headImg {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 7px;
}
.rankCon .rankItem .name {
  color: #3893fb;
  font-size: 12px;
  margin-bottom: 15px;
}
.rankCon .rankItem .label {
  color: #666666;
  font-size: 10px;
}
.rankCon .rankItem .value {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}
.rankCon .rankItem1 {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
}
.rankCon .rankItem1 .crown {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -70%);
}
.rankCon .rankItem1 .headImg {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 7px;
}
.rankCon .rankItem1 .name {
  color: #3893fb;
  font-size: 12px;
  margin-bottom: 15px;
}
.rankCon .rankItem1 .label {
  color: #666666;
  font-size: 10px;
}
.rankCon .rankItem1 .value {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}
.rankCon .rankTop1Con {
  width: 34%;
  margin: 0 auto;
  position: relative;
}
.rankCon .rankTop1Con .shadow {
  width: 8px;
  top: -4px;
  left: -8px;
  position: absolute;
}
.rankCon .rankTop1Con .shadow2 {
  width: 8px;
  top: -4px;
  right: -8px;
  position: absolute;
}
.rankCon .rankTop1Con .guanjun {
  background-color: #fff;
  position: absolute;
  top: -15px;
  border-radius: 8px;
  width: 100%;
  bottom: 0;
}
