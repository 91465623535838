







































































































































































































































































































































































































































































































































































































































































































































.homeList {
	padding-bottom: 1.2rem;
	.item_head {
		padding: 0.4rem 0 0.2rem 0;
		display: flex;
		font-family: PingFang SC;
		justify-content: space-between;
		align-items: center;
		font-size: 0.29rem;
		color: #3893fb;
		// flex: 1;
		> div {
			padding: 2px 5px;
			background-color: #f3f8fe;
			border-radius: 4px;
		}

		.name {
			// flex: 1.6;
			// width: 33%;
			padding: 0.06rem 0.15rem;
			text-align: left;
			display: flex;
			align-items: center;
		}

		.bucang {
			padding: 0.06rem 0.15rem;
			margin-left: 0.3rem;
			// flex: 1;
			// width: 17%;
			text-align: left;
			// margin-left: 0.5rem;
			span {
				// margin-left: 0.3rem;
			}
		}
		.fuyin {
			padding: 0.06rem 0.15rem;
			// flex: 1;
			// width: 20%;
			text-align: left;
			span {
				// margin-left: 0.3rem;
			}
		}

		.fudu {
			padding: 0.06rem 0.15rem;
			// flex: 1;
			// width: 30%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			// .du {
			//     padding-right: 0.15rem;
			// }
		}
	}

	.listItem {
		// position: relative;
		// margin-top: 0.2rem;
		padding: 0.2rem 0 0.2rem 0;
		border-bottom: 1px solid #f4f4f4;
		.list_top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.listTitle {
				// margin-top: 0.4rem;
				// flex: 1.6;
				// width: 38%;
				width: 2.8rem;
				.listTitle_top {
					// position: relative;
					display: flex;
					// justify-content: space-between;
					align-items: center;
					.top_right {
						margin-left: 0.12rem;
						font-size: 0.24rem;
						transform: scale(0.83);
						padding: 0 0.05rem;
						border-radius: 0.04rem;
						text-align: center;
					}
					.duo {
						background-color: rgba(50, 191, 136, 0.09);
						color: #32BF88;
					}
					.kong {
						background-color: rgba(245, 70, 93, 0.09);
						color: #F5465D;
					}
				}
				.listName {
					font-size: 0.36rem;
					font-family: Bahnschrift;

					color: #181818;
				}
				.listName1 {
					padding-left: 0.05rem;
					font-size: 0.26rem;
					font-family: Bahnschrift;
					font-weight: bold;
					color: #999;
				}

				.listName_right {
					font-size: 0.12rem;
					font-family: Bahnschrift;
					font-weight: bold;
					color: #999999;
					padding-left: 0.05rem;
				}

				.listTitle_bottom {
					padding: 0.1rem 0.2rem 0.1rem 0;
					display: flex;
					height: 0.5rem;
					align-items: center;
					justify-content: space-between;

					.new_price {
						white-space: nowrap;
						line-height: 0.5rem;
						font-size: 0.28rem;
						font-family: Bahnschrift;
						font-weight: bold;
					}

					.new_price_red {
						color: #cf4e65;
					}

					.new_price_green {
						color: #07ad91;
					}
				}

				.listType {
					height: 0.3;
					line-height: 0.3rem;
					font-size: 0.24rem;
					font-family: PingFang SC;
					color: #5eacff;
					border-radius: 0.04rem;
					background-color: #f7faff;
					// padding: 1px 2px;
					margin-left: 0.1rem;
				}
				.color_madin {
					white-space: nowrap;
					border: 1px solid #3893fb;
					color: #3893fb;
					padding: 2px;
				}
				.color_dibu {
					border: 1px solid #00bff1;
					color: #00bff1;
				}
				.color_hailang {
					border: 1px solid #5600f8;
					color: #5600f8;
				}
			}

			.listDesc {
				// flex: 1;
				// width: 12%;
				text-align: center;
				margin-right: 0.3rem;
				white-space: nowrap;

				span {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 500;
					color: #02172e;
					font-weight: bold;
					// margin-left: 0.06rem;
					letter-spacing: 1px;
				}
				// span.red {
				//     color: #CF4E65;
				// }

				// span.green {
				//     color: #07AD91;
				// }
			}

			.fuying {
				color: #373737;
				font-size: 0.31rem;
				font-family: Bahnschrift;
				// margin-left: 0.25rem;
				// margin-left: 0.05rem;
				font-weight: bold;
				// margin-right: 0.4rem;
			}

			.fuying_dian {
				padding-top: 0.1rem;
				// margin-left: 0.05rem;
				font-size: 0.28rem;
				font-family: Bahnschrift;
				// margin-right: 0.4rem;
			}
			.fuying_dian.red {
				color: #cf4e65;
			}
			.fuying_dian.green {
				color: #07ad91;
			}
			.listfuyin {
				// flex: 1;
				// width: 25%;
				width: 1.3rem;
				text-align: right;
				margin-right: 0.35rem;
			}

			.listRatio {
				// flex: 1;
				// width: 30%;
				font-size: 0.31rem;
				font-family: PingFang SC;
				font-weight: bold;
				// position: absolute;
				// right: 0;
				// top: 0.3rem;
				color: #fff;
				padding: 0.15rem 0;
				border-radius: 0.12rem !important;
				width: 1.6rem;
				text-align: center;
			}

			.listRatio.red {
				background: #cf4e65;
				border-radius: 6px;
			}

			.listRatio.green {
				background: #07ad91;
				border-radius: 6px;
			}
		}
		.list_bottom {
			margin-top: 0.1rem;
			display: flex;
			align-items: center;
			.dian {
				margin-top: 0.02rem;
				width: 0.08rem;
				height: 0.08rem;
				background-color: #f5465d;
				border-radius: 50%;
				// margin-right: 0.1rem;
			}
			.err_msg {
				margin-left: -0.05rem;
				color: #f5465d;
				font-size: 0.24rem;
				transform: scale(0.83);
			}
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;