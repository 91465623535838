


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.layoutBox {
	padding: 0 0.3rem;
}
.menuBox {
	padding: 0.24rem 0 0.15rem;
	display: flex;
	flex-wrap: wrap;
	box-shadow: 0 2px 18px rgba(202, 202, 202, 1);
}
.swipeBanner {
	height: 1.7rem;
	margin-bottom: 0.15rem;
	overflow: hidden;
	.banner {
		width: 6.9rem;
		height: 1.7rem;
		display: block;
	}
}
.swipeNotice {
	background-color: #fff;
	color: #666666;
	font-size: 0.28rem;
	height: 0.62rem;
	line-height: 0.62rem;
	padding: 0 0.15rem 0 0.65rem;
	background: #fff url(../../assets/imgs/icon/home_icon_notice.png) no-repeat left 0.15rem center;
	background-size: 0.32rem 0.3rem;
	border-radius: 0.1rem;
	margin-bottom: 0.15rem;
	overflow: hidden;
	.icon_arrR {
		float: right;
		margin: 0.2rem 0.1rem 0 0.2rem;
	}
	.van-swipe-item {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
.rankBox {
	margin-bottom: 0.25rem;
	.caption {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// position: relative;
		height: 0.6rem;
		margin-bottom: 0.8rem;
		// &::before {
		// 	content: '';
		// 	width: .04rem;
		// 	height: .24rem;
		// 	background: #90C4FF;
		// 	border-radius: 2px;
		// 	position: absolute;
		// 	left: 0;
		// 	top: 50%;
		// 	margin-top: -.12rem;
		// }
		.title {
			font-size: 0.32rem;
			font-family: PingFang SC;
			// margin-left: .14rem;
		}
		.more {
			font-size: 0.24rem;
		}
	}
	.list {
		display: flex;
		// justify-content: space-between;
		align-items: flex-end;
	}
}

.indexTabList {
	padding: 0 10px;
	.tabTit {
		color: #999999;
		font-size: 0.32rem;
		font-family: 'Bahnschrift', 'PingFangSC-Regular', 'PingFang SC', '微软雅黑', 'Microsoft Yahei';
		line-height: 0.7rem;
		height: 0.7rem;
		border-bottom: 1px solid #ededed;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.1rem;
		text-transform: capitalize;
		li {
			padding-bottom: 0.68rem;
		}
		.on {
			color: #373737;
			font-size: 0.4rem;
			font-weight: bold;
			border-bottom: 1px solid #373737;
		}
	}
}
.menuLine {
	// margin: 0.33rem 0.245rem 0;
	padding: 8px 0;
	position: relative;

	.menuName {
		font-size: 0.24rem;
		color: #373737;
		display: inline-block;
		margin-top: 0.03rem;
	}

	.menuItem {
		font-size: 0.3rem;
		// font-weight: 600;
		color: #373737;
		opacity: 0.5;
		line-height: 0.6rem;
		// text-align: center;
		// padding: 0.01rem 0 0.02rem;
		// display: inline-block;
		// width: 25%;
		// padding-left: 0.2rem;
		padding: 0 0.3rem;
		min-width: 20%;
		position: relative;
		text-transform: capitalize;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		&:first-child {
			padding-left: 0;
			&::before {
				display: none;
			}
		}
		&:last-child {
			padding-right: 0;
		}
	}

	.menuItem:last-child {
		margin-right: 0;
	}

	.menuItem.on {
		// background: #fc9216;
		// color: #90c4ff;
		opacity: 1;
		font-weight: 700;
		font-size: 0.32rem;
		color: #3893fb;
		// border-right: 1px solid #fc9216;
	}
	.dropdownBtn {
		color: #3893fb;
		font-size: 0.32rem;
	}
}

.indexLoading {
	text-align: center;
	padding-top: 1rem;
}

.bottomTips {
	color: #3893fb;
	font-size: 0.24rem;
	text-transform: uppercase;
	padding: 0 0.3rem;
	width: 100%;
	height: 0.52rem;
	line-height: 0.52rem;
	background: #f3f8ff;
	display: flex;
	justify-content: space-between;
	position: fixed;
	left: 0;
	bottom: 0;
}
.list-item0 {
	margin-left: 0.2rem;
}
.list-item2 {
	margin-left: 0.2rem;
}
.container {
	background-color: #f5f5f5;
}
.new_list {
	font-size: 12px;
	font-family: PingFang SC;
	color: #ffffff;
	padding: 0.2rem 0.4rem 0.2rem 0.3rem;
	border-radius: 6px;
	background-image: linear-gradient(to right, #8ec4ff, #ddacff);
	background-color: #8ec4ff;
	// FILTER: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr=#8EC4FF, EndColorStr=#DDACFF); /*IE6-IE8*/
	// background-image: -ms-linear-gradient(right, #8EC4FF,  #DDACFF);        /* IE 10 */
	// background-image:-moz-linear-gradient(right, #8EC4FF,#DDACFF);/*火狐*/
	// background-image:-webkit-gradient(linear, 0% 100%, 0% 100%,from(#8EC4FF), to(#DDACFF));/*谷歌*/
	// background-image: -webkit-gradient(linear, 0% 100%, 0% 100%, from(#8EC4FF), to(#DDACFF));      /* Safari 4-5, Chrome 1-9*/
	// background-image: -webkit-linear-gradient(right, #8EC4FF, #DDACFF);   /*Safari5.1 Chrome 10+*/
	// background-image: -o-linear-gradient(right, #8EC4FF, #DDACFF);  /*Opera 11.10+*/
	.leijishouyi {
		margin-bottom: 0.1rem;
	}
	.list_item {
		padding: 0.1rem 0;
		display: flex;
		align-items: center;
		.phone {
			width: 35%;
		}
		.leiji {
			width: 33%;
		}
		.jinri {
			width: 32%;
		}
	}
}
.rankCon {
	height: 3.3rem;
	background-color: #fff;
	border-radius: 6px;
	// display: flex;
	position: relative;
	.rankItem {
		position: absolute;
		left: 0;
		bottom: 0;
		text-align: center;
		// position: relative;
		.crown {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -70%);
		}
		.headImg {
			width: 39px;
			height: 39px;
			border-radius: 50%;
			object-fit: cover;
			margin-bottom: 7px;
		}
		.name {
			color: #3893fb;
			font-size: 12px;
			margin-bottom: 15px;
		}
		.label {
			color: #666666;
			font-size: 10px;
		}
		.value {
			font-size: 16px;
			color: #333;
			margin-bottom: 8px;
		}
	}
	.rankItem1 {
		position: absolute;
		right: 0;
		bottom: 0;
		text-align: center;
		// position: relative;
		.crown {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -70%);
		}
		.headImg {
			width: 39px;
			height: 39px;
			border-radius: 50%;
			object-fit: cover;
			margin-bottom: 7px;
		}
		.name {
			color: #3893fb;
			font-size: 12px;
			margin-bottom: 15px;
		}
		.label {
			color: #666666;
			font-size: 10px;
		}
		.value {
			font-size: 16px;
			color: #333;
			margin-bottom: 8px;
		}
	}
	.rankTop1Con {
		width: 34%;
		margin: 0 auto;
		position: relative;
		.shadow {
			width: 8px;
			top: -4px;
			left: -8px;
			position: absolute;
		}
		.shadow2 {
			width: 8px;
			top: -4px;
			right: -8px;
			position: absolute;
		}
		.guanjun {
			background-color: #fff;
			position: absolute;
			top: -15px;
			border-radius: 8px;
			width: 100%;
			bottom: 0;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;